






















import { Component, Vue } from "vue-property-decorator";

import VSettingsRegionCard from "../settings/cards/VSettingsRegionCard.vue";
import VSettingsSupplierCard from "../settings/cards/VSettingsSupplierCard.vue";
import VSettingsTranslateCard from "../settings/cards/VSettingsTranslateCard.vue";
import VSettingsTaxesCard from "./cards/VSettingsTaxesCard.vue";
import VSettingsOrderCard from "./cards/VSettingsOrderCard.vue";

@Component({
  name: "VSettingsManagement",
  components: {
    VSettingsRegionCard,
    VSettingsSupplierCard,
    VSettingsTranslateCard,
    VSettingsTaxesCard,
    VSettingsOrderCard,
  },
})
export default class VSettingsManagement extends Vue {}
