import axios from "axios";

import catchErrors from "../helpers/catchErrors";
import { API_URL } from "../config";

import { VenueModel } from "@/@types/model";

const getAll = catchErrors<VenueModel[]>(
  async (supplierId: string | number) => {
    const { data, status } = await axios.get(`${API_URL}/suppliers/${supplierId}/venues`);

    return status === 200 && data ? data : [];
  },
  { isThrow: true },
);

/* eslint-disable @typescript-eslint/no-unused-vars */
const save = catchErrors<boolean>(async ({ supplierId, ...venue }: VenueModel) => {
  const { status } = await axios.post(`${API_URL}/venues`, venue);

  return status === 200;
});

export default { getAll, save };
