import { render, staticRenderFns } from "./VSettingsRegionCard.vue?vue&type=template&id=21e564e8&scoped=true&"
import script from "./VSettingsRegionCard.vue?vue&type=script&lang=ts&"
export * from "./VSettingsRegionCard.vue?vue&type=script&lang=ts&"
import style0 from "./VSettingsRegionCard.vue?vue&type=style&index=0&id=21e564e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e564e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardText,VCol,VDivider,VIcon,VProgressCircular,VRow,VSelect,VSpacer,VSwitch,VToolbar,VToolbarTitle,VTooltip})
