






import { Component, Provide, Vue } from "vue-property-decorator";
import { Route, NavigationGuardNext } from "vue-router";

import VSettingsManagement from "@/components/settings/VSettingsManagement.vue";

import { confirm } from "../../helpers/dialogs";
import { settingsEvents } from "@/constants/events";

import { SettingsDirtyFlags } from "@/@types/common";

const DIRTY_FLAGS: SettingsDirtyFlags = {
  isRegionDirty: false,
  isSupplierDirty: false,
  isTranslateDirty: false,
  isTaxesDirty: false,
  isOrderDirty: false,
};

@Component({
  name: "Settings",
  components: { VSettingsManagement },
  middleware: "check-first-config",
})
export default class Settings extends Vue {
  @Provide("dirtyFlags") dirtyFlags: SettingsDirtyFlags = DIRTY_FLAGS;

  metaInfo() {
    return { title: this.$t("Navigation.Settings") };
  }

  async beforeRouteLeave(_to: Route, _from: Route, next: NavigationGuardNext) {
    const isLeavePage = Object.keys(this.dirtyFlags).every(
      (key) => this.dirtyFlags[key as keyof SettingsDirtyFlags] === false,
    );

    if (isLeavePage) next();
    else {
      const result = await this.$dialog.warning(
        confirm(undefined, this.$t("GlobalUI.UnSaveDataHelper"), {
          cancel: {
            key: "cancel",
            text: String(this.$t("GlobalUI.Cancel")),
            flat: false,
          },
          discardChanges: {
            key: "discard",
            color: "error",
            text: String(this.$t("GlobalUI.DiscardChanges")),
            flat: false,
          },
          save: {
            key: "save",
            color: "primary",
            text: String(this.$t("GlobalUI.Save")),
            flat: false,
          },
        }),
      );

      switch (result) {
        case "cancel":
          break;
        case "discardChanges":
          await this.$emitter.emit(settingsEvents.RESTORE_SETTINGS);
          next();
          break;
        case "save":
          await this.$emitter.emit(settingsEvents.SAVE_SETTINGS);
          next();
          break;
        default:
          break;
      }
    }
  }
}
