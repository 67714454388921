







































import { isEqual, cloneDeep } from "lodash";
import { Component, Watch, Vue, Inject } from "vue-property-decorator";
import { Get, Call } from "vuex-pathify";
import { validationMixin } from "vuelidate";
import { required, decimal, minValue } from "vuelidate/lib/validators";

import VVatRatesList from "../VVatRatesList.vue";

import suppliersService from "@/services/suppliers.service";
import { nonReactive } from "@/helpers/functions";
import { settingsEvents } from "@/constants/events";

import {
  AppSettingsModel,
  SupplierModel,
  UserModel,
  VatRate as VatRateModel,
} from "@/@types/model";
import { SettingsDirtyFlags } from "@/@types/common";

interface VatRate extends VatRateModel {
  isNew?: boolean;
}

type TaxesSettingsForm = {
  vatId: string;
  vatRates: VatRate[];
};

let cacheForm: TaxesSettingsForm | null = null;

const LocalVuelidate = Vue.extend({
  mixins: [validationMixin],
  provide() {
    return {
      $v: this.$v,
    };
  },
});

@Component({ name: "VSettingsTaxesCard", components: { VVatRatesList } })
export default class VSettingsTaxesCard extends LocalVuelidate {
  form: TaxesSettingsForm | null = null;

  isFormEqualCache: boolean = true;

  isLoading: boolean = true;
  isBusy: boolean = false;

  @Inject("dirtyFlags") dirtyFlags!: SettingsDirtyFlags;

  @Get("auth/user") user!: UserModel;

  @Call("app/updateSettings") updateAppSettings!: (_paylod: Partial<AppSettingsModel>) => void;

  @Watch("form", { deep: true })
  onFormChange(value: TaxesSettingsForm) {
    this.isFormEqualCache = isEqual(nonReactive(value), cacheForm);
    this.dirtyFlags.isTaxesDirty = !this.isFormEqualCache;
  }

  validations() {
    return {
      form: {
        vatRates: {
          $each: {
            value: { required, decimal, minValue: minValue(0) },
          },
        },
      },
    };
  }

  async created() {
    this.$emitter.on(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.on(settingsEvents.RESTORE_SETTINGS, this.restore);

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      const { vatId, vatRates } = (await suppliersService.getFieldsById(currentSupplier.id, [
        "vatId",
        "vatRates",
      ])) as SupplierModel;

      const form = { vatId: vatId || "", vatRates };

      this.form = form;
      cacheForm = cloneDeep(form);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  destroyed() {
    this.$emitter.off(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.off(settingsEvents.RESTORE_SETTINGS, this.restore);
  }

  restore() {
    this.form = cloneDeep(cacheForm);
  }

  async save() {
    if (this.isFormEqualCache) return;
    if (!this.form) return;
    if (this.form.vatRates.some((vatRate) => vatRate.isNew)) return;

    this.$v.$touch();
    if (this.$v.$invalid) return;

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      this.isBusy = true;

      const vatRates = [...this.form.vatRates].sort((a, b) => a.value - b.value);

      await suppliersService.saveFields(currentSupplier.id, {
        vatId: this.form.vatId,
        vatRates,
      });

      this.updateAppSettings({
        vatRates: this.form.vatRates,
      });

      cacheForm = nonReactive(this.form);
      this.isFormEqualCache = true;

      this.dirtyFlags.isTaxesDirty = false;
    } catch (error) {
      console.error(error);
    } finally {
      this.isBusy = false;
    }
  }
}
