










































































































































import moment from "moment-timezone";
import { isEqual, cloneDeep } from "lodash";
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Call, Get } from "vuex-pathify";

import suppliersService from "../../../services/suppliers.service";
import { nonReactive } from "@/helpers/functions";
import { settingsEvents } from "@/constants/events";

import { DEFAULT_CURRENCIES } from "../../../config";

import {
  AppSettingsModel,
  CountryModel,
  SupplierModel,
  SupplierRegionSettings,
  SupplierTimeZone,
  UserModel,
} from "@/@types/model";
import { DefaultCurrency } from "@/@types/config";
import { SettingsDirtyFlags } from "@/@types/common";

interface RegionSettingsForm {
  country: SupplierRegionSettings["country"];
  timeFormat: SupplierRegionSettings["timeFormat"];
  timeZone: SupplierRegionSettings["timeZone"];
  firstDayOfWeek: SupplierRegionSettings["firstDayOfWeek"];
  defaultCurrency: SupplierModel["defaultCurrency"];
  currencyPosition: SupplierModel["currencyPosition"];
  isPriceDecimal: SupplierRegionSettings["isPriceDecimal"];
}

let cacheForm: RegionSettingsForm | null = null;

@Component({ name: "VSettingsRegionCard" })
export default class VSettingsRegionCard extends Vue {
  timeZones: SupplierTimeZone[] = [];
  currencies: DefaultCurrency[] = DEFAULT_CURRENCIES;

  form: RegionSettingsForm | null = null;

  isFormEqualCache: boolean = true;

  isLoading: boolean = true;
  isBusy: boolean = false;

  @Inject("dirtyFlags") dirtyFlags!: SettingsDirtyFlags;

  @Get("auth/user") user!: UserModel;
  @Get("countries/countries") countries!: CountryModel[];

  @Call("app/updateSettings") updateAppSettings!: (_payload: Partial<AppSettingsModel>) => void;

  @Watch("form", { deep: true })
  onFormChange(value: RegionSettingsForm) {
    this.isFormEqualCache = isEqual(nonReactive(value), cacheForm);
    this.dirtyFlags.isRegionDirty = !this.isFormEqualCache;
  }

  get isShowPriceDecimalBlock() {
    if (!this.form) return null;
    return ["USD", "EUR", "PLN"].includes(this.form.defaultCurrency.label);
  }

  get currencyPositions() {
    if (!this.form) return null;
    const currency = this.form.defaultCurrency.sign;
    return [
      { value: "RS", label: `10 ${currency}` },
      { value: "R", label: `10${currency}` },
      { value: "LS", label: `${currency} 10` },
      { value: "L", label: `${currency}10` },
    ];
  }

  async created() {
    this.$emitter.on(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.on(settingsEvents.RESTORE_SETTINGS, this.restore);

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      const supplierId = currentSupplier.id;

      this.timeZones = moment.tz.names().map((zone) => ({
        offset: moment.tz(zone).format("Z"),
        label: "(GMT " + moment.tz(zone).format("Z") + ") " + zone,
      }));

      const { regionSettings, defaultCurrency, currencyPosition } =
        (await suppliersService.getFieldsById(supplierId, [
          "regionSettings",
          "defaultCurrency",
          "currencyPosition",
        ])) as SupplierModel;

      const form = {
        country: regionSettings.country,
        timeFormat: regionSettings.timeFormat,
        timeZone: regionSettings.timeZone,
        firstDayOfWeek: regionSettings.firstDayOfWeek || "mon",
        defaultCurrency,
        currencyPosition: currencyPosition || "RS",
        isPriceDecimal: regionSettings.isPriceDecimal || false,
      };

      this.form = form;
      cacheForm = cloneDeep(form);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  destroyed() {
    this.$emitter.off(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.off(settingsEvents.RESTORE_SETTINGS, this.restore);
  }

  restore() {
    this.form = cloneDeep(cacheForm);
  }

  async save() {
    if (this.isFormEqualCache) return;
    if (!this.form) return;

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      const supplierId = currentSupplier.id;

      this.isBusy = true;

      await suppliersService.saveFields(supplierId, {
        regionSettings: {
          country: this.form.country,
          timeFormat: this.form.timeFormat,
          timeZone: this.form.timeZone,
          firstDayOfWeek: this.form.firstDayOfWeek,
        },
        defaultCurrency: this.form.defaultCurrency,
        currencyPosition: this.form.currencyPosition,
      });

      this.updateAppSettings({
        country: this.form.country,
        timeFormat: this.form.timeFormat,
        defaultCurrency: this.form.defaultCurrency.sign,
        firstDayOfWeek: this.form.firstDayOfWeek,
      });

      cacheForm = nonReactive(this.form);
      this.isFormEqualCache = true;

      this.dirtyFlags.isRegionDirty = false;
    } catch (error) {
      console.error(error);
    } finally {
      this.isBusy = false;
    }
  }
}
