





















































































































import { isEqual, cloneDeep } from "lodash";
import { Component, Watch, Inject, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import venuesService from "@/services/venues.service";
import suppliersService from "@/services/suppliers.service";
import { nonReactive } from "@/helpers/functions";
import { settingsEvents } from "@/constants/events";

import {
  AppSettingsModel,
  SupplierModel,
  SupplierOrderSettings as OrderSettingsForm,
  UserModel,
  VenueModel,
} from "@/@types/model";
import { SettingsDirtyFlags } from "@/@types/common";

type OrderFieldsSetting = {
  field: string;
  key: string;
  helper: string;
  disabled: boolean;
};

const orderSettingsSchema = (): OrderSettingsForm => ({
  isOrderChannelsDineIn: false,
  isOrderChannelsPreorder: false,
  isOrderChannelsTakeAway: false,
  isOrderChannelsDriveThru: false,
  isOrderChannelsDelivery: false,
});

let cacheForm: OrderSettingsForm | null = null;

@Component({ name: "VSettingsOrderCard" })
export default class VSettingsOrderCard extends Vue {
  orderFieldsSettings: OrderFieldsSetting[] = [
    {
      field: "isOrderChannelsDineIn",
      key: "OrderChannelsDineIn",
      helper: "OrderChannelsDineInHelper",
      disabled: true,
    },
    {
      field: "isOrderChannelsTakeAway",
      key: "OrderChannelsTakeAway",
      helper: "OrderChannelsTakeAwayHelper",
      disabled: true,
    },
  ];

  orderFieldsSettingsCommingSoon: OrderFieldsSetting[] = [
    {
      field: "isOrderChannelsPreorder",
      key: "OrderChannelsPreorder",
      helper: "OrderChannelsPreorderHelper",
      disabled: true,
    },
    {
      field: "isOrderChannelsDriveThru",
      key: "OrderChannelsDriveThru",
      helper: "OrderChannelsDriveThruHelper",
      disabled: true,
    },
    {
      field: "isOrderChannelsDelivery",
      key: "OrderChannelsDelivery",
      helper: "OrderChannelsDeliveryHelper",
      disabled: true,
    },
  ];

  form: OrderSettingsForm | null = null;

  isFormEqualCache: boolean = true;

  isError: boolean = false;
  isLoading: boolean = true;
  isBusy: boolean = false;

  @Inject("dirtyFlags") dirtyFlags!: SettingsDirtyFlags;

  @Get("auth/user") user!: UserModel;
  @Get("app/settings") appSettings!: AppSettingsModel;

  @Watch("form", { deep: true })
  onFormChange(value: OrderSettingsForm) {
    this.isFormEqualCache = isEqual(nonReactive(value), cacheForm);
    this.dirtyFlags.isOrderDirty = !this.isFormEqualCache;
  }

  async created() {
    this.$emitter.on(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.on(settingsEvents.RESTORE_SETTINGS, this.restore);

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      const { defaultLanguage } = this.appSettings;

      const venues = (await venuesService.getAll(currentSupplier.id)) as VenueModel[];
      const venue = venues[0];
      const address = venue.address[defaultLanguage];

      const { orderSettings } = (await suppliersService.getFieldsById(currentSupplier.id, [
        "orderSettings",
      ])) as SupplierModel;

      this.orderFieldsSettings = this.orderFieldsSettings.map((field) => ({
        ...field,
        disabled:
          [
            "isOrderChannelsPreorder",
            "isOrderChannelsTakeAway",
            "isOrderChannelsDriveThru",
          ].includes(field.field) && !address,
      }));

      const form = {
        ...orderSettingsSchema(),
        ...(orderSettings || {}),
      };

      this.form = form;
      cacheForm = cloneDeep(form);
    } catch (error) {
      this.isError = true;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  destroyed() {
    this.$emitter.off(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.off(settingsEvents.RESTORE_SETTINGS, this.restore);
  }

  restore() {
    this.form = cloneDeep(cacheForm);
  }

  async save() {
    if (this.isFormEqualCache) return;
    if (!this.form) return;

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      this.isBusy = true;
      await suppliersService.saveFields(currentSupplier.id, { orderSettings: this.form });

      cacheForm = nonReactive(this.form);
      this.isFormEqualCache = true;

      this.dirtyFlags.isOrderDirty = false;
    } catch (error) {
      console.error(error);
    } finally {
      this.isBusy = false;
    }
  }
}
