var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-order-card"},[_c('v-card',{attrs:{"elevation":"2","height":"100%","loading":_vm.isBusy}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("Settings.CardHeaderOrderChannels"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"default","disabled":_vm.isFormEqualCache,"icon":""},on:{"click":_vm.restore}},[_c('v-icon',[_vm._v("mdi-restore")])],1),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isFormEqualCache,"icon":""},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1),_c('v-divider'),(_vm.form)?_c('v-card-text',[_c('div',{staticClass:"order-controls"},[_vm._l((_vm.orderFieldsSettings),function(field){return [_c('div',{key:("order-label-" + (field.key)),staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(("Settings." + (field.helper)))))])]),_c('h3',{staticClass:"order-controls__label text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t(("Settings." + (field.key))))+": ")])],1),_c('v-tooltip',{key:("order-switch-" + (field.key)),attrs:{"max-width":"300","disabled":!field.disabled || _vm.form[field.field],"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"v-switch v-switch--two-label d-flex items-center",attrs:{"true-value":true,"false-value":false,"disabled":field.disabled && !_vm.form[field.field],"hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticClass:"v-switch__label"},[_vm._v(" "+_vm._s(_vm.$t("GlobalUI.Off"))+" ")])]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"v-switch__label active"},[_vm._v(" "+_vm._s(_vm.$t("GlobalUI.On"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.form[field.field]),callback:function ($$v) {_vm.$set(_vm.form, field.field, $$v)},expression:"form[field.field]"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Settings.OrderChannelsPickupBlockedHelper")))])])]})],2),_c('div',{staticClass:"orange--text mt-6 mb-2",style:({ whiteSpace: 'nowrap' })},[_vm._v(" "+_vm._s(_vm.$t("GlobalUI.ComingSoon"))+" ")]),_c('div',{staticClass:"order-controls order-controls--comming-soon"},[_vm._l((_vm.orderFieldsSettingsCommingSoon),function(field){return [_c('div',{key:("order-label-" + (field.key)),staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(("Settings." + (field.helper)))))])]),_c('h3',{staticClass:"order-controls__label text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t(("Settings." + (field.key))))+": ")])],1),_c('v-switch',{key:("order-switch-" + (field.key)),staticClass:"v-switch v-switch--two-label d-flex items-center",attrs:{"true-value":true,"false-value":false,"disabled":field.disabled && !_vm.form[field.field],"hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticClass:"v-switch__label"},[_vm._v(" "+_vm._s(_vm.$t("GlobalUI.Off"))+" ")])]},proxy:true},{key:"append",fn:function(){return [_c('span',{staticClass:"v-switch__label active"},[_vm._v(" "+_vm._s(_vm.$t("GlobalUI.On"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.form[field.field]),callback:function ($$v) {_vm.$set(_vm.form, field.field, $$v)},expression:"form[field.field]"}})]})],2)]):_vm._e(),(_vm.isLoading)?_c('v-card-text',{staticClass:"d-flex justify-center align-center",style:({ height: '200px' })},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_vm._e(),(_vm.isError && !_vm.isLoading)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("Messages.SomethingWentWrong"))+"... ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }