

























































































import { isEqual, cloneDeep } from "lodash";
import { Component, Inject, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Get, Call } from "vuex-pathify";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";

import suppliersService from "../../../services/suppliers.service";
import {
  SUBDOMAIN_MAX_LENGTH,
  subdomain,
  badSubdomain,
} from "../../../services/validators.service";
import { nonReactive } from "@/helpers/functions";
import { settingsEvents } from "@/constants/events";

import { CUSTOMER_URL } from "../../../config";
import {
  AppSettingsModel,
  CountryModel,
  SupplierContactPhone,
  SupplierModel,
  UserModel,
} from "@/@types/model";
import { SettingsDirtyFlags } from "@/@types/common";

const [protocol, domain] = CUSTOMER_URL.split("{subdomain}");

const phone = (mask: string | string[]) => (phone: SupplierContactPhone) => {
  if (phone.number === "") return true;
  if (/^[0-9]+$/.test(phone.number)) return true;

  const getRegExp = (value: string) => {
    const regStr = value.replace(/#/g, "\\d").replace(/\(/g, "\\(").replace(/\)/g, "\\)");
    return new RegExp(regStr);
  };

  if (typeof mask === "string") {
    return getRegExp(mask).test(phone.number);
  } else if (Array.isArray(mask)) {
    return mask.some((m) => getRegExp(m).test(phone.number));
  }
  return false;
};

type SupplierSettingsForm = {
  title: string;
  phone: SupplierContactPhone;
  website: string;
  companyName: string;
  subdomain: string;
};

let cacheForm: SupplierSettingsForm | null = null;

@Component({ name: "VSettingsSupplierCard", directives: { mask } })
export default class VSettingsSupplierCard extends mixins(validationMixin) {
  readonly SUBDOMAIN_MAX_LENGTH: number = SUBDOMAIN_MAX_LENGTH;

  protocol: string = protocol;
  domain: string = domain;

  form: SupplierSettingsForm | null = null;

  isFormEqualCache: boolean = true;

  isLoading: boolean = true;
  isBusy: boolean = false;

  @Inject("dirtyFlags") dirtyFlags!: SettingsDirtyFlags;

  @Get("auth@user") user!: UserModel;
  @Get("app@settings.country") currentCountry!: string;
  @Get("countries@countries") countries!: CountryModel[];

  @Call("app/updateSettings") updateAppSettings!: (_paylod: Partial<AppSettingsModel>) => void;

  @Watch("form", { deep: true })
  onFormChange(value: SupplierSettingsForm) {
    this.isFormEqualCache = isEqual(nonReactive(value), cacheForm);
    this.dirtyFlags.isSupplierDirty = !this.isFormEqualCache;
  }

  validations() {
    const { mask } = this.phoneObject;
    return {
      form: {
        // title: { required },
        subdomain: { required, subdomain, badSubdomain },
        phone: { phone: phone(mask) },
        website: { url },
      },
    };
  }

  // Validation
  get phoneObject() {
    const country = this.countries.find(
      ({ alpha3Code }) => alpha3Code === this.currentCountry,
    ) as CountryModel;
    const { mask, code } = country.phone;
    if (Array.isArray(mask)) return { mask: [...mask], code };
    else if (typeof mask === "string" && mask) return { mask, code };
    return { mask: "#".repeat(20), code };
  }

  // get titleErrors() {
  //   const errors: TranslateResult[] = [];
  //   const { title } = this.$v.form;
  //   if (!title || !title.$dirty) return errors;
  //   !title.required && errors.push(this.$t("Validation.FieldRequired"));
  //   return errors;
  // }

  get subdomainErrors() {
    const result: TranslateResult[] = [];
    const { subdomain } = this.$v.form;
    if (!subdomain || !subdomain.$dirty) return [];
    if (!subdomain.required) result.push(this.$t("Validation.FieldRequired"));
    if (!subdomain.subdomain) result.push(this.$t("Validation.SubdomainHelper"));
    if (!subdomain.badSubdomain) result.push(this.$t("Validation.IncorrectSubdomain"));
    return result;
  }

  get websiteErrors() {
    const errors: TranslateResult[] = [];
    const { website } = this.$v.form;
    if (!website || !website.$dirty) return errors;
    !website.url && errors.push(this.$t("Validation.Website"));
    return errors;
  }

  get phoneErrors() {
    const errors: TranslateResult[] = [];
    const { phone } = this.$v.form;
    if (!phone || !phone.$dirty) return errors;
    !phone.phone && errors.push(this.$t("Validation.Phone"));
    return errors;
  }

  async created() {
    this.$emitter.on(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.on(settingsEvents.RESTORE_SETTINGS, this.restore);

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      const { title, contactData, legalData, subdomain } = (await suppliersService.getFieldsById(
        currentSupplier.id,
        ["title", "contactData.phone", "contactData.website", "legalData.companyName", "subdomain"],
      )) as SupplierModel;

      const form = {
        title,
        phone: contactData.phone,
        website: contactData.website,
        companyName: legalData.companyName,
        subdomain,
      };

      this.form = form;
      cacheForm = cloneDeep(form);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  destroyed() {
    this.$emitter.off(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.off(settingsEvents.RESTORE_SETTINGS, this.restore);
  }

  restore() {
    this.form = cloneDeep(cacheForm);
  }

  async save() {
    if (this.isFormEqualCache) return;
    if (!this.form) return;

    this.$v.$touch();
    if (this.$v.$invalid) return;

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      this.isBusy = true;

      const subdomain = this.form.subdomain.toLowerCase();

      await suppliersService.saveFields(currentSupplier.id, {
        title: this.form.title || "",
        contactData: {
          phone: {
            code: this.phoneObject.code,
            number: this.form.phone.numberFormatted.replace(/[^0-9]+/g, ""),
            numberFormatted: this.form.phone.numberFormatted,
          },
          website: this.form.website.toLowerCase(),
        },
        legalData: {
          companyName: this.form.companyName,
        },
        subdomain,
      });

      this.updateAppSettings({ subdomain });

      cacheForm = nonReactive(this.form);
      this.isFormEqualCache = true;

      this.dirtyFlags.isSupplierDirty = false;
    } catch (e) {
      console.error(e);
    } finally {
      this.isBusy = false;
    }
  }
}
