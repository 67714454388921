var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-transalte-card"},[_c('v-card',{attrs:{"elevation":"2","height":"100%","loading":_vm.isBusy}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("Settings.CardHeaderLanguages"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"default","disabled":_vm.isFormEqualCache,"icon":""},on:{"click":_vm.restore}},[_c('v-icon',[_vm._v("mdi-restore")])],1),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isFormEqualCache,"icon":""},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1),_c('v-divider'),(_vm.form)?_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.languages,"label":_vm.$t('Settings.DefaultLanguage'),"menu-props":{ offsetY: true, closeOnClick: true },"disabled":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("visibleLanguage")(item))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("visibleLanguage")(item))+" ")]}}],null,false,1367390684),model:{value:(_vm.form.defaultLanguage),callback:function ($$v) {_vm.$set(_vm.form, "defaultLanguage", $$v)},expression:"form.defaultLanguage"}}),_c('v-autocomplete',{ref:"select",staticClass:"alternative-languages",attrs:{"items":_vm.alternativeLanguages,"search-input":_vm.searchAlternativeLanguage,"menu-props":{
          offsetY: false,
          offsetOverflow: false,
          closeOnClick: true,
          contentClass: 'select-menu select-menu--with-header',
        },"label":_vm.$t('Settings.AlternativeLanguages'),"item-text":"label","item-value":"code","append-outer-icon":_vm.isShowLanguageSelect ? 'mdi-check' : undefined,"multiple":""},on:{"update:searchInput":function($event){_vm.searchAlternativeLanguage=$event},"update:search-input":function($event){_vm.searchAlternativeLanguage=$event},"change":function($event){_vm.searchAlternativeLanguage = null}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-sheet',{staticClass:"select-menu__header"},[_c('v-text-field',{attrs:{"label":((_vm.$t('GlobalUI.Search')) + "..."),"hide-details":"","outlined":"","dense":""},model:{value:(_vm.searchAlternativeLanguage),callback:function ($$v) {_vm.searchAlternativeLanguage=$$v},expression:"searchAlternativeLanguage"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.select.blur()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
        var attrs = ref.attrs;
        var item = ref.item;
        var selected = ref.selected;
return [_c('v-chip',_vm._b({key:item.code,attrs:{"input-value":selected,"close":"","label":"","small":""},on:{"click:close":function($event){return _vm.handleDeleteAlternativeLanguage(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"item",fn:function(ref){
        var item = ref.item;
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),_vm.onAlternativeLanguage(item, on)),[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"value":_vm.isSelectedAlternativeLanguage(item),"ripple":false}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)],1)]}}],null,false,3027250238),model:{value:(_vm.form.alternativeLanguages),callback:function ($$v) {_vm.$set(_vm.form, "alternativeLanguages", $$v)},expression:"form.alternativeLanguages"}})],1):_vm._e(),(_vm.isLoading)?_c('v-card-text',{staticClass:"d-flex justify-center align-center",style:({ height: '200px' })},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_vm._e(),(!_vm.form && !_vm.isLoading)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("Messages.SomethingWentWrong"))+"... ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }