





































































































import { isEqual, cloneDeep } from "lodash";
import { Component, Watch, Vue, Inject } from "vue-property-decorator";
import { Get, Call } from "vuex-pathify";

import VVerifyPasswordCard from "@/components/VVerifyPasswordCard.vue";

import suppliersService from "@/services/suppliers.service";
import { nonReactive } from "@/helpers/functions";
import { settingsEvents } from "@/constants/events";

import { AppSettingsModel, SupplierModel, UserModel } from "@/@types/model";
import { Language } from "@/store/countries/types";
import { SettingsDirtyFlags } from "@/@types/common";

interface TranslateSettingsForm {
  defaultLanguage: string;
  alternativeLanguages: string[];
}

let cacheForm: TranslateSettingsForm | null = null;

@Component({ name: "VSettingsTranslateCard" })
export default class VSettingsTranslateCard extends Vue {
  searchAlternativeLanguage: string | null = null;

  form: TranslateSettingsForm | null = null;

  isFormEqualCache: boolean = true;

  isLoading: boolean = true;
  isBusy: boolean = false;

  isShowLanguageSelect: boolean = false;

  @Inject("dirtyFlags") dirtyFlags!: SettingsDirtyFlags;

  @Get("auth/user") user!: UserModel;
  @Get("app/settings") appSettings!: AppSettingsModel;

  @Call("app/updateSettings") updateAppSettings!: (_paylod: Partial<AppSettingsModel>) => void;

  @Watch("form", { deep: true })
  onFormChange(value: TranslateSettingsForm) {
    this.isFormEqualCache = isEqual(nonReactive(value), cacheForm);
    this.dirtyFlags.isTranslateDirty = !this.isFormEqualCache;
  }

  get onAlternativeLanguage() {
    return (item: Language, on: () => void) =>
      this.isSelectedAlternativeLanguage(item)
        ? { click: () => this.handleDeleteAlternativeLanguage(item) }
        : on;
  }

  get isSelectedAlternativeLanguage() {
    if (!this.form) return () => false;
    const { alternativeLanguages } = this.form;
    return ({ code }: Language) => alternativeLanguages.includes(code);
  }

  get languages() {
    if (!this.form) return [];
    const { defaultLanguage, alternativeLanguages } = this.form;
    return [defaultLanguage, ...alternativeLanguages];
  }

  get alternativeLanguages() {
    if (!this.form) return [];
    const { defaultLanguage } = this.form;
    const { country } = this.appSettings;
    const languages = this.$store.getters["countries/languagesForCountry"](country) as Language[];
    return languages.filter(({ code }) => code !== defaultLanguage);
  }

  async created() {
    this.$emitter.on(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.on(settingsEvents.RESTORE_SETTINGS, this.restore);

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      const { defaultLanguage, alternativeLanguages } = (await suppliersService.getFieldsById(
        currentSupplier.id,
        ["defaultLanguage", "alternativeLanguages"],
      )) as SupplierModel;

      const form = { defaultLanguage, alternativeLanguages };

      this.form = form;
      cacheForm = cloneDeep(form);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  destroyed() {
    this.$emitter.off(settingsEvents.SAVE_SETTINGS, this.save);
    this.$emitter.off(settingsEvents.RESTORE_SETTINGS, this.restore);
  }

  async handleDeleteAlternativeLanguage(language: Language) {
    if (!this.form) return;

    const isValid = await this.$dialog.showAndWait(VVerifyPasswordCard, {
      showClose: false,
      description: this.$t("Settings.DeleteAlternativeLanguageHelper"),
    });
    if (!isValid) return;

    this.form.alternativeLanguages = this.form.alternativeLanguages.filter(
      (code) => code !== language.code,
    );
  }

  restore() {
    this.form = cloneDeep(cacheForm);
  }

  async save() {
    if (this.isFormEqualCache) return;
    if (!this.form) return;

    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      this.isBusy = true;

      await suppliersService.saveFields(currentSupplier.id, {
        defaultLanguage: this.form.defaultLanguage,
        alternativeLanguages: this.form.alternativeLanguages,
      });

      this.updateAppSettings({
        defaultLanguage: this.form.defaultLanguage,
        alternativeLanguages: this.form.alternativeLanguages,
      });

      cacheForm = nonReactive(this.form);
      this.isFormEqualCache = true;

      this.dirtyFlags.isTranslateDirty = false;
    } catch (error) {
      console.error(error);
    } finally {
      this.isBusy = false;
    }
  }
}
