



































import { v4 as uuid } from "uuid";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";

import { confirm } from "@/helpers/dialogs";
import { VatRate as VatRateModel } from "@/@types/model";

interface VatRate extends VatRateModel {
  isNew: boolean;
}

const LocalVuelidate = Vue.extend({
  inject: { $v: "$v" },
});

@Component({ name: "VVatRatesList" })
export default class VVatRatesList extends LocalVuelidate {
  @Prop({ type: Array, default: () => [] }) readonly value!: VatRate[];

  /* Validations */
  get valueErrors() {
    return (idx: string) => {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.vatRates!.$each[idx]!.value!.$dirty) return errors;
      !this.$v.form.vatRates!.$each[idx]!.value!.required &&
        errors.push(this.$t("Validation.FieldRequired"));
      !this.$v.form.vatRates!.$each[idx]!.value!.decimal &&
        errors.push(this.$t("Validation.Numeric"));
      !this.$v.form.vatRates!.$each[idx]!.value!.minValue &&
        errors.push(this.$t("Validation.Min.Numeric", { min: 0 }));

      return errors;
    };
  }

  handleChangeValueItem(value: VatRate[], id: string) {
    const newValue = this.value.map((item) => (item.id === id ? { id, value } : item));
    this.$emit("input", newValue);
  }

  async handleClickDeleteItem({ id, isNew }: VatRate) {
    if (!isNew) {
      const result = await this.$dialog.warning(
        confirm(this.$t("Settings.VatRateDelete"), this.$t("Settings.VatRateDeleteWarning")),
      );
      if (!result) return;
    }

    this.$emit(
      "input",
      this.value.filter((item) => item.id !== id),
    );
  }

  handleClickAddItem() {
    this.$emit("input", [
      ...this.value,
      {
        id: uuid(),
        value: null,
        isNew: true,
      },
    ]);
  }
}
